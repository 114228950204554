import { NgModule } from '@angular/core';

import { AdminRoutingModule } from './admin-routing.module';


@NgModule({
  imports: [
    AdminRoutingModule,
  ]
})
export class AdminModule { }
